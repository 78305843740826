import { combineReducers } from 'redux';
import auth from './auth/';
import customizer from './customizer/';
import navbar from './navbar/';

const rootReducer = combineReducers({
  customizer,
  auth,
  navbar,
});

export default rootReducer;
